import {Alert, Button, Grid} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import processingImage from '../icons/dollar-stack.gif'
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import paymentsApi from "../PaymentsApi";
import {monitorStatus} from "../util/MonitorStatus";

export const Processing = () => {

    const [data, setData] = useState({
        id: '0'
    })
    const [queryParameters] = useSearchParams()

    const location = useLocation()
    const navigate = useNavigate();

    useEffect(() => {
        let encodedData = queryParameters.get("data");
        if ((location.state === undefined || location.state === null) && encodedData) {
            paymentsApi.getPaymentByData(encodedData)
                .then(res => {
                    setData(res.data)
                })
                .catch(e => console.log("Cannot obtain payment status"))
        } else {
            setData(location.state)
        }

    }, [])

    useEffect(() => {
        if (data.id === '0' || data.id === null) return;

        const controller = new AbortController();
        const signal = controller.signal;

        (async () => {
            let payment = () => paymentsApi.getPayment(data.id)
            let validate = (result: any) => {
                return !(result.data.paymentStatus === 'DONE' ||
                    result.data.paymentStatus === 'ERROR_INTERNAL' ||
                    result.data.paymentStatus === 'ERROR_EXTERNAL' ||
                    signal.aborted);
            }
            let response = await monitorStatus(payment, validate, 1000);
            if (response.data.paymentStatus === 'DONE') {
                navigate("/success", {state: response.data})
            } else {
                navigate("/error", {state: response.data})
            }
        })()

        return () => controller.abort();

    }, [data]);

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}
        >

                <Grid item xs={12}>
                    <img src={processingImage} width='256px' />
                </Grid>
            <Grid item xs={12}>
                <Alert severity="info">Przetwarzanie płatności</Alert>
            </Grid>

            {/*DEV ONLY CONFIRM MANUALLY PAYMENT*/}
            <Grid item xs={12}>
                <Button sx={{ m: 3}} variant="outlined" color="error" onClick={() => {
                    paymentsApi.confirmPayment(data.id)
                        .then(response => {
                            console.log("Confirmed manually payment")
                        })
                        .catch(reason => console.log("ERROR on manually payment approve"));

                }} > Confirm payment id {data.id} manually </Button>
            </Grid>
            </Grid>
    )
}

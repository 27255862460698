import {Alert, Grid} from "@mui/material";
import alertSymbol from "../icons/alert-symbol.gif";
import React from "react";

export const NotFound = () => {
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}
        >
            <Grid item xs={12}>
                <img src={alertSymbol} width='256px' alt="Not found" />
            </Grid>
            <Grid item xs={12}>
                <Alert severity="error">Podana strona nie istnieje</Alert>
            </Grid>
        </Grid>
    )
}
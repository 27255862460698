import IPaymentRequest from "./types/IPaymentRequest";
import http from "./http-common";

const createPayment = (data: IPaymentRequest) => {
    return http.post<IPaymentRequest>("/payments", data)
}

const confirmPayment = (paymentId: string) => {
    return http.get(`/gateway/manual/success/${paymentId}`)
}

const getPayment = (paymentId: string) => {
    return http.get(`/payments/${paymentId}`)
}

const getPaymentByData = (data: string) => {

    return http.get(`/gateway/cinkciarz/payments/${data}`)
}

const PaymentApi = {
    createPayment,
    confirmPayment,
    getPayment,
    getPaymentByData
};

export default PaymentApi;

import {useDescription, useTsController} from "@ts-react/form";
import {Grid, InputAdornment, TextField} from "@mui/material";
import React from "react";

export default function NumberFormField() {
    const {
        field: {onChange, value},
        error,
    } = useTsController<number>();
    const {label} = useDescription()

    return (
        <>
            <Grid item xs={12}>
                <TextField
                    label={label}
                    id={label}
                    helperText={error && error.errorMessage}
                    error={error !== undefined}
                    onChange={(e) => {
                        const value = parseInt(e.target.value);
                        if (isNaN(value)) onChange(undefined);
                        else onChange(value);
                    }}
                    value={value ? value : ""}
                    fullWidth
                    InputProps={{
                        endAdornment: <InputAdornment position="end">zł</InputAdornment>,
                    }}
                />
            </Grid>
        </>
    );
}

import {useDescription, useTsController} from "@ts-react/form";
import {FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup} from "@mui/material";
import IPaymentMethod from "../../types/IPaymentMethod";

export default function PaymentCheckboxField({ options }: { options: IPaymentMethod[] }) {
    const { field: {onChange, value}, error } = useTsController<string>();
    const {label} = useDescription()

    return (
        <>
            <FormControl sx={{ m: 3 }} error={!!error} variant="standard">
                <FormLabel id="demo-radio-buttons-group-label" >{label}</FormLabel>
                <RadioGroup name='payment-method-group' >
                    <Grid container >
                    {options.map((value, index) => {
                        return (
                            <Grid item  key={index}>
                                <FormControlLabel control={<Radio
                                                            checkedIcon={<img src={value.imgLink} style={{borderStyle: "dotted",  width: '120px', height: '60px'}}
                                                                              alt={value.name}/>}
                                                            icon={<img src={value.imgLink} alt={value.name}
                                                                       style={{ width: '100px', height: '50px' }}
                                                            />} />}
                                                  label={""} value={value.name}
                                                  onChange={event => onChange(value.name)}/>
                            </Grid>
                        )
                    })}
                    </Grid>
                </RadioGroup>
                <FormHelperText>{error?.errorMessage && error.errorMessage}</FormHelperText>
            </FormControl>
        </>
    );
}

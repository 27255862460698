import {useDescription, useTsController} from "@ts-react/form";
import React from "react";
import {Grid, TextField} from "@mui/material";

export default function TextFormField() {
    const {
        field: {onChange, value},
        error,
    } = useTsController<string>();
    const {label} = useDescription()

    return (
        <>
            <Grid item xs={12} sm={6}>
                <TextField
                    name={label}
                    required
                    fullWidth
                    id={label}
                    label={label}
                    autoFocus
                    value={value ? value : ""}
                    onChange={(e) => onChange(e.target.value)}
                    helperText={error && error.errorMessage}
                    error={error !== undefined}
                />
            </Grid>
        </>
    );
}
import React, {useEffect, useState} from "react";
import IDonatePageInfo from "../types/IDonatePageInfo";
import donatePageInfoApi from "../DonatePageInfoApi";
import {createTsForm, createUniqueFieldSchema} from "@ts-react/form";
import {z} from "zod";
import TextFormField from "../form/field/TextFormField";
import NumberFormField from "../form/field/NumberFormField";
import MultiLineFormTextField from "../form/field/MultiLineFormTextField";
import PaymentCheckboxField from "../form/field/PaymentCheckboxField";
import DonateCustomFormComponent from "../form/component/DonateCustomFormComponent";
import IPaymentRequest from "../types/IPaymentRequest";
import paymentsApi from "../PaymentsApi";
import {Avatar, Box, Container, createTheme, CssBaseline, ThemeProvider, Typography} from "@mui/material";
import {HeartBroken} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";
import { FidgetSpinner } from "react-loader-spinner";

export const Donate = () => {

    const [donatePageInfo, setDonatePageInfo] = useState<IDonatePageInfo>({
        donatePageName: 'Error',
        minimalAmount: -666,
        userId: '0',
        maxCharactersTextDonate: 0,
        paymentMethods: [],
        avatar: '',
        publicUserName: 'No Data'
    });

    const { publicUsername } = useParams() as { publicUsername: string };
    const navigate = useNavigate();

    useEffect(() => {
        donatePageInfoApi.getDonatePageInfo(publicUsername)
            .then(response => {
                setDonatePageInfo(response.data)
            }).catch(e => navigate("/"));
    }, [])

    if (donatePageInfo.userId === '0') {
        return (
            <FidgetSpinner
                visible={true}
                height="80"
                width="80"
                ariaLabel="fidget-spinner-loading"
                wrapperStyle={{}}
                wrapperClass="fidget-spinner-wrapper"
            />
        );
    }

    const TextFormSchema = createUniqueFieldSchema(
        z.string({required_error: "Podaj treść wiadomości"}).max(donatePageInfo.maxCharactersTextDonate, `Maksymalna długość wiadomości to ${donatePageInfo.maxCharactersTextDonate}`),
        "text"
    );

    const BlikFormSchema = createUniqueFieldSchema(
        z.number().int().describe("Enter BLIK code"),
        "blikCode"
    )

    // CO ZA DEBIL TO TAK ZAPROJEKTOWAŁ?!?!?!?!
    const paymentMethods : [string, ...string[]]= [donatePageInfo.paymentMethods[0].name, ...donatePageInfo.paymentMethods.slice(1).map((p) => p.name)]

    const mapping = [
        [z.string(), TextFormField],
        [z.number(), NumberFormField],
        [TextFormSchema, MultiLineFormTextField],
        [z.enum(paymentMethods), PaymentCheckboxField]
    ] as const;

    const MyForm = createTsForm(mapping, {FormComponent: DonateCustomFormComponent});

    const DonateSchema = z.object({
        nickname: z.string({required_error: "Podaj swój nick"}).min(3, `Minimalna długość nicka to 3 znaki`).describe("Nick"),
        email: z.string({required_error: "Podaj swój adres email"}).email("Wprowadź swój adres email").describe("E-mail"),
        amount: z.number({required_error: "Podaj kwotę"}).min(donatePageInfo.minimalAmount, `Minimalna kwota to ${donatePageInfo.minimalAmount} zł`)
            .describe("Kwota"),
        text: TextFormSchema.describe("Wiadomość"),
        paymentMethod: z.enum(paymentMethods).describe("Metoda płatności"),
        // blikCode: BlikFormSchema
    })

    function onSubmit(data: z.infer<typeof DonateSchema>) {
        const {nickname, email, amount, text, paymentMethod} = data
        const request: IPaymentRequest = {
            donateType: 'TEXT', //TODO Introduce list of available donates types for selected user
            amount: amount,
            message: text,
            senderEmail: email,
            title: nickname,
            paymentMethod: paymentMethod,
            publicUserName: publicUsername,
            blikCode: '',
            firstName: nickname,
            lastName: nickname,
            browserClientMeta: {
                userScreenResolution: window.innerWidth + 'x' + window.innerHeight
            }
        }

        paymentsApi.createPayment(request)
            .then((response: any) => {
                if (response.status === 200) {
                    window.location.href = response.data.approveUrl
                }
            })
            .catch((e: Error) => {
                console.log(e)
            })
    }

    const defaultTheme = createTheme();

    return (
        <main>
            <ThemeProvider theme={defaultTheme}>
                <Container component="main" >
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <CssBaseline />
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main', width: 128, height: 128 }} src={donatePageInfo.avatar}
                                alt={donatePageInfo.publicUserName}>
                            <HeartBroken />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            {donatePageInfo.donatePageName}
                        </Typography>
                        <MyForm schema={DonateSchema} onSubmit={onSubmit} props={{
                            paymentMethod: {
                                options: donatePageInfo.paymentMethods
                            },
                            text: {
                                maxCharacters: donatePageInfo.maxCharactersTextDonate
                            }
                        }} />
                    </Box>
                </Container>
            </ThemeProvider>
        </main>
    )
}

import {useDescription, useTsController} from "@ts-react/form";
import {Grid, TextField} from "@mui/material";
import React from "react";

export default function MultiLineFormTextField({maxCharacters} : { maxCharacters: number }) {
    const {
        field: {onChange, value},
        error,
    } = useTsController<string>();

    const {label} = useDescription()
    let characterCounter = value?.length ?? 0

    return (
        <>
            <Grid item xs={12}>
                <TextField
                    id={label}
                    label={label}
                    multiline
                    fullWidth
                    rows={4}
                    helperText={error? error.errorMessage + " (" + characterCounter + " / " + maxCharacters + ")" : characterCounter + " / " + maxCharacters}
                    error={error !== undefined}
                    onChange={(e) => onChange(e.target.value)}
                    value={value ? value : ""}
                    inputProps={{
                        maxLength: maxCharacters, type: 'text'
                    }}
                />
            </Grid>
        </>
    )
}

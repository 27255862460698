import React, {ReactNode, useEffect, useState} from "react";
import {Box, Button, Checkbox, FormControlLabel, Grid, Typography} from "@mui/material";

export default function DonateCustomFormComponent({
  children,
  onSubmit
}:{
  children: ReactNode,
  onSubmit: () => void
}) {

    const [isPolicyAccepted, setIsPolicyAccepted] = useState<boolean>(false);

    useEffect(() => {

    }, [isPolicyAccepted])

    return (
        <Box component="form" noValidate onSubmit={onSubmit} sx={{ m: 3 }}>
            <Grid container spacing={2}>
                {children}
                <Grid item xs={12}>
                    <FormControlLabel
                        componentsProps={{typography: {variant: 'h6'}}}
                        control={<Checkbox value="allowExtraEmails" color="primary" checked={isPolicyAccepted} onChange={event => {
                            setIsPolicyAccepted(event.target.checked)
                        }} />}
                        label={
                            <Typography sx={{ fontSize: 12 }}>
                                Deklaruję moje pełne zrozumienie i zgodę na niezwłoczne rozpoczęcie świadczenia usługi.
                                Ponadto, jestem świadomy(a), że po wykonaniu umowy w całości, nie będę miał(a) prawa do odstąpienia od umowy,
                                jako że została ona zawarta na odległość.
                            </Typography>
                        }
                    />
                </Grid>
            </Grid>

            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={!isPolicyAccepted}
            > Wyślij </Button>

        </Box>
  )
}


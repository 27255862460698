import http from "./http-common";
import IDonatePageInfo from "./types/IDonatePageInfo";

const getDonatePageInfo = (userName: string) => {
    return http.get<IDonatePageInfo>(`/users/${userName}/donate-page-info-public`);
}

const DonatePageInfoApi = {
    getDonatePageInfo
};

export default DonatePageInfoApi;